import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import moment from "moment";

import Layout from "../components/layout";
import { Link, useIntl } from "gatsby-plugin-react-intl";
import Image from "../components/image";

const startHour = 18;

const Timetable = ({ data }) => {
  const intl = useIntl();
  const [stage, setStage] = useState(null);
  const [date, setDate] = useState(null);
  const [place, setPlace] = useState(null);
  const [visibleArtists, setVisibleArtists] = useState(1);

  const [tableView, setTableView] = useState(true);

  const stages = data.allWpStages.nodes

  console.log(stages)

  useEffect(() => {
    countVisibleArtists();
  }, [stage, date, place]);

  const artists = [
    ...data.allWpArtists.nodes
      .filter((i) => i.artist?.artisttype === "artist")
      .sort((a, b) => {
        return (
          new Date(a.artist.hour?.replace(/-/g, "/")) -
          new Date(b.artist.hour?.replace(/-/g, "/"))
        );
      }),
  ];

  const getArtistsForDate = (date) => {
    const filteredArtists = artists.filter((artist) => {
      return (
        artist.artist.festivaldate && artist.artist.festivaldate[0].id === date
      );
    });
    return filteredArtists;
  };

  const getPlacesForDate = (date) => {
    const filteredArtists = getArtistsForDate(date);

    const places = [
      ...new Map(
        filteredArtists.map((item) => [
          item.artist.scene[0].stages.place[0].id,
          item,
        ])
      ).values(),
    ];

    return places.map((place) => {
      return place.artist.scene[0].stages.place[0];
    });
  };

  const getStagesForDateAndPlace = (date, place) => {
    const filteredArtists = getArtistsForDate(date);

    const filteredStages = filteredArtists.filter((artist) => {
      return artist.artist.scene[0].stages.place[0].id === place;
    });

    const newarray = [
      ...new Set(filteredStages.map((item) => item.artist.scene[0].title)),
    ];

    console.log(newarray);

    const finalArray = [];

    stages.map((stage) => {
      if (newarray.includes(stage.title)) {
        finalArray.push(stage.title);
      }
    })


    return finalArray;
  };

  const countVisibleArtists = () => {
    let counter = 0;
    data.allWpArtists.nodes.map((i) =>
      (stage === null || stage.includes(i.artist.scene[0].id)) &&
      (date === null || i.artist.festivaldate[0].id === date)
        ? counter++
        : null
    );

    setVisibleArtists(counter);
  };

  const startDate = new Date("2020-01-01T18:00");

  const getFormattedStartDate = (date) => {
    const splitted = date.split(".");
    return new Date(splitted[2], splitted[1] - 1, splitted[0], startHour, 0);
  };

  const getInitialDistance = (startDate, artistDate) => {
    // console.log(new Date(artistDate.replace(/-/g, "/")));
    // console.log(getFormattedStartDate(startDate));
    // console.log(
    //   new Date(artistDate.replace(/-/g, "/")).getTime(),
    //   getFormattedStartDate(startDate).getTime()
    // );
    // console.log(
    //   (new Date(artistDate.replace(/-/g, "/")).getTime() -
    //     getFormattedStartDate(startDate).getTime()) /
    //     1000 /
    //     60 /
    //     15
    // );
    return (
      (new Date(artistDate.replace(/-/g, "/")) - startDate) / 1000 / 60 / 15
    );
  };

  const getRowsCounterForDateAndPlace = (date, place) => {
    const artists = getArtistsForDate(date);

    const filteredArtists = artists.filter((artist) => {
      return artist.artist.scene[0].stages.place[0].id === place;
    });

    const scaleStart = new Date(
      filteredArtists[0].artist.hour.replace(/-/g, "/")
    );

    // scaleStart.setMinutes(scaleStart.getMinutes() - 30);

    const scaleEnd = new Date(
      filteredArtists[filteredArtists.length - 1].artist.hourEnd.replace(
        /-/g,
        "/"
      )
    );

    scaleEnd.setMinutes(scaleEnd.getMinutes() + 30);


    const scale = (scaleEnd - scaleStart) / 1000 / 60 / 15 / 2;

    return { counter: scale, start: scaleStart, end: scaleEnd };
  };

  const getHoursForPlace = (place, date) => {
    const artists = getArtistsForDate(date);

    return artists.map((artist) => {
      return { start: artist.artist.hour, end: artist.artist.hourEnd };
    });
  };

  const getLength = (artist) => {
    return (
      (new Date(artist.artist.hourEnd.replace(/-/g, "/")) -
        new Date(artist.artist.hour.replace(/-/g, "/"))) /
      1000 /
      60 /
      15
    );
  };

  return (
    <Layout>
      <main className="">
        <header className=" py-10 sm:pt-[7.5rem]">
          <h1 className="flex flex-wrap items-center justify-between gap-4 px-4 big-heading">
            Timetable
            <Link className="text-xl font-normal lg:text-2xl" to="/artists">
              {intl.formatMessage({ id: "Artists" })} &rarr;
            </Link>
          </h1>
        </header>

        <div className="px-4 mx-auto space-y-8 max-w-7xl lg:space-y-16 lg:py-8 lg:px-8">
          {/* <div className="grid grid-cols-2 border border-utdf-border">
            <button
              onClick={() => setTableView(true)}
              className={`${
                tableView
                  ? "bg-utdf-border text-utdf-dark font-bold"
                  : "text-utdf-border"
              } p-2 `}
            >
              {intl.formatMessage({ id: "TableView" })}
            </button>
            <button
              onClick={() => setTableView(false)}
              className={`${
                !tableView
                  ? "bg-utdf-border text-utdf-dark font-bold"
                  : "text-utdf-border"
              } p-2 `}
            >
              {intl.formatMessage({ id: "ListView" })}
            </button>
          </div> */}

          {tableView
            ? data.allWpDates.nodes.map((date, key) => (
                <section key={key} className="-mx-4">
                  <h2 className="text-2xl pl-14  border-b border-utdf-border py-2  bg-utdf-border text-center text-utdf-dark font-bold sticky top-[3.5rem] md:top-[6.5rem] z-20">
                    {date.title}
                  </h2>

                  {getPlacesForDate(date.id).map((place, key) => (
                    <section className="mt-16" key={key}>
                      <a target="_blank" rel="noopener noreferrer" href={`https://maps.google.com/?q=${place.places.address}`}>
                        <div className="flex flex-col items-center justify-center gap-3 mb-8 pl-14">
                          <h3 className="px-4 py-2 text-lg font-bold text-center text-black rounded-full sm:text-2xl bg-utdf-secondary">
                            {place.title}
                          </h3>
                          <p class="text-utdf-secondary text-sm italic">
                            {place.places.address}
                          </p>
                        </div>
                      </a>
                      <div className="pl-14 grid w-full grid-flow-col text-sm italic text-center auto-cols-[1fr] sm:text-lg font-editorial text-utdf-secondary">
                        {getStagesForDateAndPlace(date.id, place.id).map(
                          (stage) => (
                            <div className="p-2">{stage}</div>
                          )
                        )}
                      </div>
                      <div
                        className="overflow-x-auto"
                        style={{
                          background:
                            "repeating-linear-gradient(0deg, transparent, transparent 23px, rgba(255, 255, 255, .1) 23px, rgba(255, 255, 255, .1) 24px )",
                        }}
                      >
                        <div
                          className="flex overflow-x-auto flex-nowrap"
                          style={{}}
                        >
                          <div className="sticky left-0 z-10 basis-[50px]">
                            <div className="grid auto-rows-[1rem] gap-2 -translate-y-5 z-10 ">
                              <div className="row-span-2"></div>
                              {[
                                ...Array(
                                  getRowsCounterForDateAndPlace(
                                    date.id,
                                    place.id
                                  ).counter
                                ),
                              ].map((_, i) => (
                                <div className="row-span-2 pl-1 pr-2 text-xs whitespace-nowrap text-utdf-border md:pr-4 md:text-sm">
                                  {String(
                                    new Date(
                                      getRowsCounterForDateAndPlace(
                                        date.id,
                                        place.id
                                      ).start.getTime() +
                                        i * 30 * 60000
                                    ).getHours()
                                  ).padStart(2, "0")}
                                  :
                                  {String(
                                    new Date(
                                      getRowsCounterForDateAndPlace(
                                        date.id,
                                        place.id
                                      ).start.getTime() +
                                        i * 30 * 60000
                                    ).getMinutes()
                                  ).padStart(2, "0")}
                                </div>
                              ))}
                            </div>
                          </div>

                          <div
                            className="grid gap-2 pr-1 grow"
                            style={{
                              gridAutoFlow: "column",
                              gridAutoColumns:
                                "minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr)  minmax(150px, 1fr)",
                            }}
                          >
                            {data.allWpStages.nodes.map(
                              (stage) =>
                                stage.stages.place[0].id === place.id &&
                                artists.filter(
                                  ({ artist }) =>
                                    artist?.festivaldate &&
                                    artist?.festivaldate[0]?.id === date.id &&
                                    artist.scene[0].id === stage.id
                                ).length > 0 && (
                                  <div className="grid auto-rows-[1rem] gap-2  h-full -translate-y-1">
                                    {artists
                                      .filter(
                                        (artist) =>
                                          artist?.artist?.festivaldate &&
                                          artist?.artist?.festivaldate[0]
                                            ?.id === date.id &&
                                          artist.artist.scene[0].id === stage.id
                                      )
                                      .map((artist, key) => (
                                        <>
                                          {key === 0 ? (
                                            getInitialDistance(
                                              getRowsCounterForDateAndPlace(
                                                date.id,
                                                place.id
                                              ).start,
                                              artist.artist.hour
                                            ) > 0 ? (
                                              <div
                                                style={{
                                                  gridRow: `span  ${
                                                    getInitialDistance(
                                                      getRowsCounterForDateAndPlace(
                                                        date.id,
                                                        place.id
                                                      ).start,
                                                      artist.artist.hour
                                                    ) + 2
                                                  }`,
                                                }}
                                                className="flex items-end justify-center text-center translate-y-1"
                                              >
                                                <span className="sticky block font-bold tracking-wide top-40 font-editorial text-utdf-border"></span>
                                              </div>
                                            ) : (
                                              <div className="flex items-end justify-center row-span-2 p-2 text-center translate-y-3 bg-opacity-90 ">
                                                <span className="sticky block font-bold tracking-wide top-40 font-editorial text-utdf-border"></span>
                                              </div>
                                            )
                                          ) : null}

                                          <div
                                            style={{
                                              gridRow: `span  ${getLength(
                                                artist
                                              )}`,
                                            }}
                                            pl
                                            className="flex items-center justify-center font-bold text-center bg-opacity-75 border rounded-lg border-utdf-border bg-utdf text-utdf-border "
                                          >
                                            {artist.artist.timetableOnly ? (
                                              <p className="p-2">
                                                {artist.title}
                                              </p>
                                            ) : (
                                              <Link
                                                to={`/artists/${artist.slug}`}
                                                title={artist.title}
                                                className="flex items-center justify-center w-full h-full gap-2 p-2 text-sm text-center grow sm:text-base"
                                              >
                                                {artist.title.split(" live")[0]}{" "}
                                                {artist.title.split(" live")
                                                  .length > 1 ? (
                                                  <span className="text-sm italic font-normal font-editorial">
                                                    {" "}
                                                    live
                                                  </span>
                                                ) : null}{" "}
                                              </Link>
                                            )}
                                          </div>
                                        </>
                                      ))}
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                  ))}
                </section>
              ))
            : data.allWpDates.nodes.map((date, key) => (
                <section className="pb-10">
                  <h2 className="-mx-4 text-2xl border-b border-utdf-border py-2 mb-8 bg-utdf-border text-center text-utdf-dark font-bold sticky top-16 md:top-[6.5rem] z-20">
                    {date.title}
                  </h2>

                  <div className="flex justify-center">
                    {data.allWpPlaces.nodes.map((place, key) => (
                      <div>
                        <h3 className="px-4 py-2 mb-8 text-lg font-bold text-center text-black rounded-full sm:text-2xl bg-utdf-secondary">
                          {place.title}
                        </h3>
                      </div>
                    ))}
                  </div>
                  <div
                    className="grid gap-8"
                    style={{
                      gridTemplateColumns:
                        "repeat(auto-fit, minmax(200px, 1fr))",
                    }}
                  >
                    {stages.map((stage, key) => {
                      return artists.filter(
                        ({ artist }) =>
                          artist?.festivaldate[0]?.id === date.id &&
                          artist.scene[0].id === stage.id
                      ).length > 0 ? (
                        <section key={key}>
                          {artists
                            .filter(
                              (artist) =>
                                artist?.artist?.festivaldate[0]?.id ===
                                  date.id &&
                                artist.artist.scene[0].id === stage.id
                            )
                            .map((artist, key) => (
                              <>
                                {key === 0 ? (
                                  <h3 className="mb-4 font-bold tracking-wider font-editorial text-utdf-border">
                                    {intl.locale === "pl"
                                      ? stage.title
                                      : stage.stages.titleEn}
                                  </h3>
                                ) : null}

                                <p className="mb-2">
                                  {" "}
                                  <span className="inline-block pr-2 text-xs md:text-sm text-utdf-secondary">
                                    {String(
                                      new Date(
                                        artist.artist.hour.replace(/-/g, "/")
                                      ).getHours()
                                    ).padStart(2, "0")}
                                    :
                                    {String(
                                      new Date(
                                        artist.artist.hour.replace(/-/g, "/")
                                      ).getMinutes()
                                    ).padStart(2, "0")}{" "}
                                  </span>
                                  <Link
                                    to={`/artists/${artist.slug}`}
                                    title={artist.title}
                                  >
                                    {artist.title.split(" live")[0]}{" "}
                                    {artist.title.split(" live").length > 1 ? (
                                      <span className="text-sm italic font-normal font-editorial">
                                        {" "}
                                        live
                                      </span>
                                    ) : null}
                                  </Link>
                                </p>
                              </>
                            ))}
                        </section>
                      ) : null;
                    })}
                  </div>
                </section>
              ))}
        </div>
        {/*
      <ul
        className="grid gap-4 sm:gap-6"
        style={{
          gridTemplateColumns: "repeat(auto-fill, minmax(212px, 1fr))",
        }}
      >
        {artists.map(
          (i, key) =>
            i.artist.festivaldate[0].title.includes("2022") && (
              <li
                className={`basis-[270px] shrink-0 group ${
                  (stage === null ||
                    stage.includes(i.artist.scene[0].id)) &&
                  (date === null ||
                    i.artist.festivaldate[0].id === date)
                    ? "block"
                    : "hidden"
                }`}
                key={key}
              >
                <Link
                  className="flex flex-col justify-between h-full pb-8"
                  to={`/artists/${i.slug}`}
                  title={i.title}
                >
                  <div className="mb-2 ">
                    <GatsbyImage
                      image={
                        i.artist.photo
                          ? getImage(i.artist.photo.localFile)
                          : null
                      }
                      alt={i.artist.title}
                      className="block w-full h-full"
                    />
                  </div>
                  <p className="mt-2 mb-auto text-2xl leading-9 group-hover:underline">
                    {i.title}
                  </p>
                  <div className="flex flex-col justify-between">
                    <div className="flex justify-between gap-4 pt-4 mt-4 border-t border-utdf-border font-gambetta">
                      <p>
                        {intl.locale === "pl"
                          ? i.artist.scene[0].title
                          : i.artist.scene[0].stages.titleEn}
                      </p>
                      <p>{i.artist.festivaldate[0].title} </p>
                    </div>
                  </div>
                </Link>
              </li>
            )
        )}
      </ul> */}
      </main>
    </Layout>
  );
};

const TimetableWrapper = () => {
  return (
    <>
      <StaticQuery
        query={graphql`
          query ShortArtistsListTimetable {
            allWpPlaces {
              nodes {
                id
                places {
                  nameEn
                  namePl
                  address
                }
              }
            }
            allWpStages(sort: { fields: slug }) {
              nodes {
                id
                slug
                stages {
                  place {
                    ... on WpPlaces {
                      id
                      places {
                        nameEn
                        namePl
                        address
                      }
                    }
                  }
                  order
                  titleEn
                }
                title
              }
            }
            allWpDates {
              nodes {
                title
                slug
                id
              }
            }
            allWpPlaces {
              nodes {
                title
                id
              }
            }
            allWpArtists(sort: { fields: slug }) {
              nodes {
                id
                title
                slug
                artist {
                  photo {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  festivaldate {
                    ... on WpDates {
                      id
                      title
                    }
                  }
                  artisttype
                  hour
                  hourEnd
                  timetableOnly
                  scene {
                    ... on WpStages {
                      id
                      stages {
                        place {
                          ... on WpPlaces {
                            id
                            title
                            places {
                              nameEn
                              namePl
                              address
                            }
                          }
                        }
                        titleEn
                      }
                      title
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          return <Timetable data={data} />;
        }}
      />
    </>
  );
};

export default TimetableWrapper;
